import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const images = require.context('../assets/img/', true);
const imagePath = (name) => images(name, true);

export default function References(data) {
	return (
		<section id="referenzen" className="mlg_section mlg_references mb-5rem mb-md-50px">
			<Container>
				<Row>
					<Col xs={12}>
						<div className="mlg_heading mb-4-75rem mb-md-26px mb-sm-26px mb-xs-26px">
							<h2>{data.title}</h2>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12}>
						<Row className="mb-3rem mb-sm-48px mb-xs-48px">
							<Col lg={3} md={12} className="text-md-center mb-md-48px mb-sm-48px mb-xs-48px">
								<img
									src={imagePath('./' + data.references[0].logo.src)}
									alt={data.references[0].logo.alt}
								/>
							</Col>

							<div className="col-lg-1 col-md-12"></div>

							<div className="col-lg-4 col-md-12 pl-xl-0px pl-lg-0px mb-md-30px mb-sm-28px mb-xs-28px">
								<p>{data.references[0].description[0]}</p>
							</div>

							<div className="col-lg-4 col-md-12">
								<p>{data.references[0].description[1]}</p>
							</div>
						</Row>
						<Row>
							<Col lg={6} md={12} className="mb-md-48px mb-sm-48px mb-xs-48px">
								<img
									className="mlg_img-custom-87362"
									src={imagePath('./' + data.references[0].img.src)}
									alt={data.references[0].img.alt}
								/>
							</Col>

							<Col lg={6} md={12} className="align-self-end">
								<div className="mlg_testimonial">
									<div className="mlg_t-content">
										<p>{data.references[0].testimonial.content}</p>
									</div>
									<div className="mlg_t-author">
										<div className="mlg_t-a-image">
											<img
												src={imagePath('./' + data.references[0].testimonial.author.img.src)}
												alt={data.references[0].testimonial.author.img.alt}
											/>
										</div>

										<h5 className="mlg_t-a-name">{data.references[0].testimonial.author.name}</h5>
										<p
											className="mlg_t-a-position"
											dangerouslySetInnerHTML={{
												__html: data.references[0].testimonial.author.position,
											}}
										/>
									</div>
								</div>
							</Col>
						</Row>
						{/* <Carousel interval={null} wrap={false}>
              {data.references.map((reference, key) => {
                return (
                  <Carousel.Item key={key}>
                    <Row className="mb-3rem mb-sm-48px mb-xs-48px">
                      <Col
                        lg={3}
                        md={12}
                        className="text-md-center mb-md-48px mb-sm-48px mb-xs-48px"
                      >
                        <img
                          src={imagePath("./" + reference.logo.src)}
                          alt={reference.logo.alt}
                        />
                      </Col>

                      <div className="col-lg-1 col-md-12"></div>

                      <div className="col-lg-4 col-md-12 pl-xl-0px pl-lg-0px mb-md-30px mb-sm-28px mb-xs-28px">
                        <p>{reference.description[0]}</p>
                      </div>

                      <div className="col-lg-4 col-md-12">
                        <p>{reference.description[1]}</p>
                      </div>
                    </Row>

                    <Row>
                      <Col
                        lg={6}
                        md={12}
                        className="mb-md-48px mb-sm-48px mb-xs-48px"
                      >
                        <img
                          className="mlg_img-custom-87362"
                          src={imagePath("./" + reference.img.src)}
                          alt={reference.img.alt}
                        />
                      </Col>

                      <Col lg={6} md={12} className="align-self-end">
                        <div className="mlg_testimonial">
                          <div className="mlg_t-content">
                            <p>{reference.testimonial.content}</p>
                          </div>
                          <div className="mlg_t-author">
                            <div className="mlg_t-a-image">
                              <img
                                src={imagePath(
                                  "./" + reference.testimonial.author.img.src
                                )}
                                alt={reference.testimonial.author.img.alt}
                              />
                            </div>

                            <h5 className="mlg_t-a-name">
                              {reference.testimonial.author.name}
                            </h5>
                            <p
                              className="mlg_t-a-position"
                              dangerouslySetInnerHTML={{
                                __html: reference.testimonial.author.position,
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Carousel.Item>
                )
              })}
            </Carousel> */}
					</Col>
				</Row>
			</Container>
		</section>
	);
}
